<template>
  <v-dialog v-model="dialog" retain-focus max-width="600" @click:outside="$emit('cancel')" @keydown.esc="$emit('cancel')">
    <v-card ripple class="px-4 py-2">
      <v-card-title class="headline justify-center"><v-icon color="error" class="mr-2 mb-1">mdi-alert-circle</v-icon>ATENÇÃO</v-card-title>
      <p class="px-4 mt-4 text-center">{{ text }}</p>
      <v-card-actions class="pt-2">
        <v-btn class="mb-2 mr-2" block color="primary" outlined @click="$emit('proceed')"><v-icon class="mr-2">mdi-content-save</v-icon> Continuar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    }
  }
}
</script>
